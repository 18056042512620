import { title } from 'process';

const contentTopics = [
    {
        title: 'Alertness',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Attitude',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Essential Documents',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Hazard Awareness',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Incidents, Accidents and Emergencies',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Motorway Rules',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Other Types of Vehicle',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Road and Traffic Signs',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Rules of the Road',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Safety and your vehicle',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Safety Margins',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: 'Vehicle Handling',
        description:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
];

export default contentTopics;
