import { HazardPerceptionVideoResult } from "./components";

const FeatHazardPerceptionResult = () => {
  return (
    <aside>
      <HazardPerceptionVideoResult  />
    </aside>
  );
};

export default FeatHazardPerceptionResult;
