import page1 from './attitude/page1';
import page2 from './attitude/page2';
import page3 from './attitude/page3';
import page4 from './attitude/page4';
import page5 from './attitude/page5';

import page6 from './alertness/page1';
import page7 from './alertness/page2';
import page8 from './alertness/page3';
import page9 from './alertness/page4';
import page10 from './alertness/page5';

import page11 from './essential-documents/page1';
import page12 from './essential-documents/page2';
import page13 from './essential-documents/page3';
import page14 from './essential-documents/page4';
import page15 from './essential-documents/page5';

import page16 from './hazard-awareness/page1';
import page17 from './hazard-awareness/page2';
import page18 from './hazard-awareness/page3';
import page19 from './hazard-awareness/page4';
import page20 from './hazard-awareness/page5';
import page21 from './hazard-awareness/page6';

import page22 from './incidents-accidents-and-emergencies/page1';
import page23 from './incidents-accidents-and-emergencies/page2';
import page24 from './incidents-accidents-and-emergencies/page3';
import page25 from './incidents-accidents-and-emergencies/page4';
import page26 from './incidents-accidents-and-emergencies/page5';
import page28 from './incidents-accidents-and-emergencies/page6';
import page29 from './incidents-accidents-and-emergencies/page7';

import page30 from './motorway-rules/page1';
import page31 from './motorway-rules/page2';
import page32 from './motorway-rules/page3';
import page33 from './motorway-rules/page4';
import page34 from './motorway-rules/page5';
import page36 from './motorway-rules/page6';
import page37 from './motorway-rules/page7';

import page38 from './other-types-of-vehicle/page1';
import page39 from './other-types-of-vehicle/page2';
import page40 from './other-types-of-vehicle/page3';
import page41 from './other-types-of-vehicle/page4';
import page42 from './other-types-of-vehicle/page5';
import page43 from './other-types-of-vehicle/page6';

import page44 from './road-and-traffic-signs/page1';
import page45 from './road-and-traffic-signs/page2';
import page46 from './road-and-traffic-signs/page3';
import page47 from './road-and-traffic-signs/page4';
import page48 from './road-and-traffic-signs/page5';
import page49 from './road-and-traffic-signs/page6';
import page50 from './road-and-traffic-signs/page7';

import page51 from './rules-of-the-road/page1';
import page52 from './rules-of-the-road/page2';
import page53 from './rules-of-the-road/page3';
import page54 from './rules-of-the-road/page4';
import page55 from './rules-of-the-road/page5';
import page56 from './rules-of-the-road/page6';
import page57 from './rules-of-the-road/page7';
import page58 from './rules-of-the-road/page8';
import page59 from './rules-of-the-road/page9';

import page60 from './safety-and-your-vehicle/page1';
import page61 from './safety-and-your-vehicle/page2';
import page62 from './safety-and-your-vehicle/page3';
import page63 from './safety-and-your-vehicle/page4';
import page64 from './safety-and-your-vehicle/page5';
import page65 from './safety-and-your-vehicle/page6';

import page66 from './safety-margins/page1';
import page67 from './safety-margins/page2';
import page68 from './safety-margins/page3';
import page69 from './safety-margins/page4';
import page70 from './safety-margins/page5';
import page71 from './safety-margins/page6';

import page72 from './vehicle-handling/page1';
import page73 from './vehicle-handling/page2';
import page74 from './vehicle-handling/page3';
import page75 from './vehicle-handling/page4';
import page76 from './vehicle-handling/page5';
import page77 from './vehicle-handling/page6';

import page78 from './vehicle-loading/page1';
import page79 from './vehicle-loading/page2';
import page80 from './vehicle-loading/page3';
import page81 from './vehicle-loading/page4';

import page82 from './vulnerable-road-users/page1';
import page83 from './vulnerable-road-users/page2';
import page84 from './vulnerable-road-users/page3';
import page85 from './vulnerable-road-users/page4';
import page86 from './vulnerable-road-users/page5';
import page87 from './vulnerable-road-users/page6';
import page88 from './vulnerable-road-users/page7';
import page89 from './vulnerable-road-users/page8';
import page90 from './vulnerable-road-users/page9';

import topics from './study-topics';

export const DATA_LEARN = {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16,
    page17,
    page18,
    page19,
    page20,
    page21,
    page22,
    page23,
    page24,
    page25,
    page26,
    page28,
    page29,
    page30,
    page31,
    page32,
    page33,
    page34,
    page36,
    page37,
    page38,
    page39,
    page40,
    page41,
    page42,
    page43,
    page44,
    page45,
    page46,
    page47,
    page48,
    page49,
    page50,
    page51,
    page52,
    page53,
    page54,
    page55,
    page56,
    page57,
    page58,
    page59,
    page60,
    page61,
    page62,
    page63,
    page64,
    page65,
    page66,
    page67,
    page68,
    page69,
    page70,
    page71,
    page72,
    page73,
    page74,
    page75,
    page76,
    page77,
    page78,
    page79,
    page80,
    page81,
    page82,
    page83,
    page84,
    page85,
    page86,
    page87,
    page88,
    page89,
    page90,
    topics,
};
