export const RedirectOkIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path
                d="M8.96791 8.89906V1.0321M8.96791 1.0321H1.10095M8.96791 1.0321L1.10095 8.89891"
                stroke="white"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const AlertsIcon = () => {
    return (
        <svg width="31" height="36" viewBox="0 0 31 36" fill="none">
            <path
                d="M24.3854 18.5552C24.3854 13.6449 20.3337 9.66589 15.3337 9.66589C10.3337 9.66589 6.28198 13.6449 6.28198 18.5552V28.8556H24.3854V18.5552Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.2647 32.6653C28.2647 30.57 26.519 28.8556 24.3854 28.8556H6.28196C4.14834 28.8556 2.40265 30.57 2.40265 32.6653V34.0763C2.40265 34.4643 2.72592 34.7818 3.12104 34.7818H27.5463C27.9414 34.7818 28.2647 34.4643 28.2647 34.0763V32.6653Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.3337 5.43999V1.19991C15.3337 1.19991 15.348 1.19286 15.348 1.19991V5.43293L15.3337 5.43999Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.0147 7.19667C22.0147 7.19667 22.0147 7.19667 22.0147 7.18962L23.4515 4.93201C23.4515 4.93201 23.4515 4.93201 23.4587 4.93201C23.4587 4.93201 23.4587 4.93201 23.4587 4.93906L22.0219 7.19667H22.0147Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.907 12.0012V11.9941L29.9242 10.5831H29.9314V10.5902L26.9142 12.0012H26.907Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.65266 7.19667L7.21588 4.93906V4.93201H7.22307L8.65985 7.18962V7.19667H8.65266Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.76761 12.0012L0.750366 10.5902C0.750366 10.5902 0.750366 10.5902 0.750366 10.5831C0.750366 10.5831 0.750366 10.5831 0.75755 10.5831L3.77479 11.9941C3.77479 11.9941 3.77479 11.9941 3.77479 12.0012H3.76761Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.1139 20.3895C18.1139 18.8304 16.828 17.5675 15.2403 17.5675C13.6527 17.5675 12.3668 18.8304 12.3668 20.3895C12.3668 21.4337 12.9487 22.3297 13.8035 22.8235V28.8556H16.6771V22.8235C17.532 22.3367 18.1139 21.4337 18.1139 20.3895Z"
                stroke="white"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CymbalIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 31 24" fill="none">
            <path
                d="M26.9316 5.76224C26.6419 4.69633 25.543 4.06708 24.4771 4.35675L10.2654 8.21903C6.44475 9.25735 4.21531 13.2893 5.28511 17.2257C6.35492 21.1622 10.3184 23.5111 14.1391 22.4728C17.4652 21.5688 19.5853 18.3961 19.3726 14.9927C19.3157 14.0822 19.6734 13.1506 20.4662 12.6992L26.351 9.34851C27.1438 8.8971 27.5306 7.96638 27.2914 7.08599L26.9316 5.76224Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.45886 5.25138C5.63824 6.2897 3.40879 10.3216 4.4786 14.2581V14.2581C4.56918 14.5914 5.04774 14.4982 5.0975 14.1564C5.50212 11.3775 7.45014 8.99012 10.2669 8.22462L24.922 4.24185C25.743 4.01872 26.2277 3.17225 26.0046 2.35121V2.35121C25.7815 1.53018 24.935 1.04548 24.114 1.26861L9.45886 5.25138Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.0371 15.6728C5.03689 15.6721 5.0358 15.6722 5.03584 15.673C5.03599 15.6759 5.03621 15.6796 5.03654 15.6835C5.03673 15.6858 5.03179 15.6874 5.03074 15.6853C4.91708 15.4595 4.81679 15.2298 4.72344 14.9889C4.71163 14.9584 4.68255 14.9379 4.64985 14.938C4.45728 14.9387 4.26029 14.9573 4.06764 15.0096C2.79229 15.3562 2.04915 16.7002 2.40625 18.0142C2.76336 19.3282 4.08453 20.1112 5.35988 19.7646V19.7646C5.89294 19.6197 5.9818 18.9749 5.74559 18.4756C5.55957 18.0823 5.40512 17.6676 5.28658 17.2314C5.14435 16.7081 5.0661 16.1853 5.03712 15.673C5.03712 15.6729 5.03711 15.6729 5.0371 15.6728V15.6728Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.6607 14.406C16.1948 16.3715 15.0782 18.3909 13.1705 18.9094C11.2629 19.4278 9.27772 18.2513 8.74357 16.2858C8.20942 14.3204 9.32609 12.3009 11.2337 11.7825C13.1413 11.2641 15.1265 12.4405 15.6607 14.406Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const GridIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 25 25" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.7603 1.673H5.06355C4.19707 1.673 3.64344 1.67424 3.22345 1.70856C2.82214 1.74135 2.68568 1.79618 2.62796 1.82559C2.36454 1.95981 2.15036 2.17398 2.01614 2.43741C1.98673 2.49513 1.9319 2.63158 1.89911 3.0329C1.86479 3.45288 1.86355 4.00652 1.86355 4.873V6.56975C1.86355 7.43623 1.86479 7.98986 1.89911 8.40985C1.9319 8.81116 1.98673 8.94762 2.01614 9.00534C2.15036 9.26876 2.36454 9.48294 2.62796 9.61716C2.68568 9.64657 2.82214 9.7014 3.22345 9.73419C3.64344 9.76851 4.19707 9.76975 5.06355 9.76975H6.7603C7.62678 9.76975 8.18042 9.76851 8.60041 9.73419C9.00172 9.7014 9.13817 9.64657 9.19589 9.61716C9.45932 9.48294 9.67349 9.26876 9.80771 9.00534C9.83712 8.94762 9.89196 8.81116 9.92474 8.40985C9.95906 7.98986 9.9603 7.43623 9.9603 6.56975V4.873C9.9603 4.00652 9.95906 3.45288 9.92474 3.0329C9.89196 2.63158 9.83712 2.49513 9.80771 2.43741C9.67349 2.17398 9.45932 1.95981 9.19589 1.82559C9.13817 1.79618 9.00172 1.74135 8.60041 1.70856C8.18042 1.67424 7.62678 1.673 6.7603 1.673ZM0.59053 1.71103C0.26355 2.35276 0.26355 3.19284 0.26355 4.873V6.56975C0.26355 8.24991 0.26355 9.08999 0.59053 9.73172C0.87815 10.2962 1.33709 10.7552 1.90158 11.0428C2.54331 11.3698 3.38339 11.3698 5.06355 11.3698H6.7603C8.44046 11.3698 9.28054 11.3698 9.92227 11.0428C10.4868 10.7552 10.9457 10.2962 11.2333 9.73172C11.5603 9.08999 11.5603 8.24991 11.5603 6.56975V4.873C11.5603 3.19284 11.5603 2.35276 11.2333 1.71103C10.9457 1.14654 10.4868 0.687599 9.92227 0.399978C9.28054 0.072998 8.44046 0.072998 6.7603 0.072998H5.06355C3.38339 0.072998 2.54331 0.072998 1.90158 0.399978C1.33709 0.687599 0.87815 1.14654 0.59053 1.71103Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0571 1.673H18.3603C17.4938 1.673 16.9402 1.67424 16.5202 1.70856C16.1189 1.74135 15.9824 1.79618 15.9247 1.82559C15.6613 1.95981 15.4471 2.17398 15.3129 2.43741C15.2835 2.49513 15.2286 2.63158 15.1959 3.0329C15.1615 3.45288 15.1603 4.00652 15.1603 4.873V6.56975C15.1603 7.43623 15.1615 7.98986 15.1959 8.40985C15.2286 8.81116 15.2835 8.94762 15.3129 9.00534C15.4471 9.26876 15.6613 9.48294 15.9247 9.61716C15.9824 9.64657 16.1189 9.7014 16.5202 9.73419C16.9402 9.76851 17.4938 9.76975 18.3603 9.76975H20.0571C20.9235 9.76975 21.4772 9.76851 21.8972 9.73419C22.2985 9.7014 22.4349 9.64657 22.4926 9.61716C22.7561 9.48294 22.9702 9.26876 23.1045 9.00534C23.1339 8.94762 23.1887 8.81116 23.2215 8.40985C23.2558 7.98986 23.2571 7.43623 23.2571 6.56975V4.873C23.2571 4.00652 23.2558 3.45288 23.2215 3.0329C23.1887 2.63158 23.1339 2.49513 23.1045 2.43741C22.9702 2.17398 22.7561 1.95981 22.4926 1.82559C22.4349 1.79618 22.2985 1.74135 21.8972 1.70856C21.4772 1.67424 20.9235 1.673 20.0571 1.673ZM13.8873 1.71103C13.5603 2.35276 13.5603 3.19284 13.5603 4.873V6.56975C13.5603 8.24991 13.5603 9.08999 13.8873 9.73172C14.1749 10.2962 14.6338 10.7552 15.1983 11.0428C15.8401 11.3698 16.6801 11.3698 18.3603 11.3698H20.0571C21.7372 11.3698 22.5773 11.3698 23.219 11.0428C23.7835 10.7552 24.2425 10.2962 24.5301 9.73172C24.8571 9.08999 24.8571 8.24991 24.8571 6.56975V4.873C24.8571 3.19284 24.8571 2.35276 24.5301 1.71103C24.2425 1.14654 23.7835 0.687599 23.219 0.399978C22.5773 0.072998 21.7372 0.072998 20.0571 0.072998H18.3603C16.6801 0.072998 15.8401 0.072998 15.1983 0.399978C14.6338 0.687599 14.1749 1.14654 13.8873 1.71103Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.7603 14.9698H5.06355C4.19707 14.9698 3.64344 14.971 3.22345 15.0053C2.82214 15.0381 2.68568 15.0929 2.62796 15.1223C2.36454 15.2566 2.15036 15.4707 2.01614 15.7342C1.98673 15.7919 1.9319 15.9283 1.89911 16.3296C1.86479 16.7496 1.86355 17.3033 1.86355 18.1698V19.8665C1.86355 20.733 1.86479 21.2866 1.89911 21.7066C1.9319 22.1079 1.98673 22.2444 2.01614 22.3021C2.15036 22.5655 2.36454 22.7797 2.62796 22.9139C2.68568 22.9433 2.82214 22.9982 3.22345 23.0309C3.64344 23.0653 4.19707 23.0665 5.06355 23.0665H6.7603C7.62678 23.0665 8.18042 23.0653 8.60041 23.0309C9.00172 22.9982 9.13817 22.9433 9.19589 22.9139C9.45932 22.7797 9.67349 22.5655 9.80771 22.3021C9.83712 22.2444 9.89196 22.1079 9.92474 21.7066C9.95906 21.2866 9.9603 20.733 9.9603 19.8665V18.1698C9.9603 17.3033 9.95906 16.7496 9.92474 16.3296C9.89196 15.9283 9.83712 15.7919 9.80771 15.7342C9.67349 15.4707 9.45932 15.2566 9.19589 15.1223C9.13817 15.0929 9.00172 15.0381 8.60041 15.0053C8.18042 14.971 7.62678 14.9698 6.7603 14.9698ZM0.59053 15.0078C0.26355 15.6495 0.26355 16.4896 0.26355 18.1698V19.8665C0.26355 21.5467 0.26355 22.3867 0.59053 23.0285C0.87815 23.593 1.33709 24.0519 1.90158 24.3395C2.54331 24.6665 3.38339 24.6665 5.06355 24.6665H6.7603C8.44046 24.6665 9.28054 24.6665 9.92227 24.3395C10.4868 24.0519 10.9457 23.593 11.2333 23.0285C11.5603 22.3867 11.5603 21.5467 11.5603 19.8665V18.1698C11.5603 16.4896 11.5603 15.6495 11.2333 15.0078C10.9457 14.4433 10.4868 13.9844 9.92227 13.6967C9.28054 13.3698 8.44046 13.3698 6.7603 13.3698H5.06355C3.38339 13.3698 2.54331 13.3698 1.90158 13.6967C1.33709 13.9844 0.87815 14.4433 0.59053 15.0078Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0571 14.9698H18.3603C17.4938 14.9698 16.9402 14.971 16.5202 15.0053C16.1189 15.0381 15.9824 15.0929 15.9247 15.1223C15.6613 15.2566 15.4471 15.4707 15.3129 15.7342C15.2835 15.7919 15.2286 15.9283 15.1959 16.3296C15.1615 16.7496 15.1603 17.3033 15.1603 18.1698V19.8665C15.1603 20.733 15.1615 21.2866 15.1959 21.7066C15.2286 22.1079 15.2835 22.2444 15.3129 22.3021C15.4471 22.5655 15.6613 22.7797 15.9247 22.9139C15.9824 22.9433 16.1189 22.9982 16.5202 23.0309C16.9402 23.0653 17.4938 23.0665 18.3603 23.0665H20.0571C20.9235 23.0665 21.4772 23.0653 21.8972 23.0309C22.2985 22.9982 22.4349 22.9433 22.4926 22.9139C22.7561 22.7797 22.9702 22.5655 23.1045 22.3021C23.1339 22.2444 23.1887 22.1079 23.2215 21.7066C23.2558 21.2866 23.2571 20.733 23.2571 19.8665V18.1698C23.2571 17.3033 23.2558 16.7496 23.2215 16.3296C23.1887 15.9283 23.1339 15.7919 23.1045 15.7342C22.9702 15.4707 22.7561 15.2566 22.4926 15.1223C22.4349 15.0929 22.2985 15.0381 21.8972 15.0053C21.4772 14.971 20.9235 14.9698 20.0571 14.9698ZM13.8873 15.0078C13.5603 15.6495 13.5603 16.4896 13.5603 18.1698V19.8665C13.5603 21.5467 13.5603 22.3867 13.8873 23.0285C14.1749 23.593 14.6338 24.0519 15.1983 24.3395C15.8401 24.6665 16.6801 24.6665 18.3603 24.6665H20.0571C21.7372 24.6665 22.5773 24.6665 23.219 24.3395C23.7835 24.0519 24.2425 23.593 24.5301 23.0285C24.8571 22.3867 24.8571 21.5467 24.8571 19.8665V18.1698C24.8571 16.4896 24.8571 15.6495 24.5301 15.0078C24.2425 14.4433 23.7835 13.9844 23.219 13.6967C22.5773 13.3698 21.7372 13.3698 20.0571 13.3698H18.3603C16.6801 13.3698 15.8401 13.3698 15.1983 13.6967C14.6338 13.9844 14.1749 14.4433 13.8873 15.0078Z"
                fill="white"
            />
        </svg>
    );
};

export const TrafficLightIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 31 32" fill="none">
            <path
                d="M19.8044 1.625H12.1377C10.7263 1.625 9.58215 2.74429 9.58215 4.125V24.125C9.58215 25.5057 10.7263 26.625 12.1377 26.625H19.8044C21.2158 26.625 22.3599 25.5057 22.3599 24.125V4.125C22.3599 2.74429 21.2158 1.625 19.8044 1.625Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5266 26.625H13.4155V30.375H18.5266V26.625Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9711 16.625C17.3825 16.625 18.5266 15.5057 18.5266 14.125C18.5266 12.7443 17.3825 11.625 15.9711 11.625C14.5597 11.625 13.4155 12.7443 13.4155 14.125C13.4155 15.5057 14.5597 16.625 15.9711 16.625Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9711 24.125C17.3825 24.125 18.5266 23.0057 18.5266 21.625C18.5266 20.2443 17.3825 19.125 15.9711 19.125C14.5597 19.125 13.4155 20.2443 13.4155 21.625C13.4155 23.0057 14.5597 24.125 15.9711 24.125Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9711 9.125C17.3825 9.125 18.5266 8.00571 18.5266 6.625C18.5266 5.24429 17.3825 4.125 15.9711 4.125C14.5597 4.125 13.4155 5.24429 13.4155 6.625C13.4155 8.00571 14.5597 9.125 15.9711 9.125Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.2767 8.5H22.36V4.125H26.8322V6C26.8322 7.38125 25.6886 8.5 24.2767 8.5Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.2767 16H22.36V11.625H26.8322V13.5C26.8322 14.8813 25.6886 16 24.2767 16Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.2767 23.5H22.36V19.125H26.8322V21C26.8322 22.3813 25.6886 23.5 24.2767 23.5Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66554 8.5H9.58221V4.125H5.10999V6C5.10999 7.38125 6.2536 8.5 7.66554 8.5Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66554 16H9.58221V11.625H5.10999V13.5C5.10999 14.8813 6.2536 16 7.66554 16Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66554 23.5H9.58221V19.125H5.10999V21C5.10999 22.3813 6.2536 23.5 7.66554 23.5Z"
                stroke="#A14EF4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CloseIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 19 19" fill="none">
            <path
                d="M17.5141 1.45709L1.42829 17.5429M1.42829 1.45709L17.5141 17.5429"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ArrowNextIcon = () => {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
            <path
                d="M17.1828 11L11.2339 5.4L12.7477 4L21.2462 12L12.7477 20L11.2339 18.6L17.1828 13L4.24927 13V11L17.1828 11Z"
                fill="#FEF7FF"
            />
        </svg>
    );
};

export const ArrowUpIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
                d="M7 4.06332L1.4 10.0122L9.53674e-07 8.49844L8 0L16 8.49844L14.6 10.0122L9 4.06332V16.9969H7V4.06332Z"
                fill="#343131"
            />
        </svg>
    );
};

export const PlayIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path
                d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20 16L32 24L20 32V16Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const PhoneRotateIcon = () => {
    return (
        <svg width="100" height="125" viewBox="0 0 100 125" fill="none">
            <path
                d="M30.26 21.0071H6.818C5.67312 21.0081 4.57544 21.4634 3.76588 22.273C2.95633 23.0825 2.50106 24.1802 2.5 25.3251V74.6751C2.50106 75.82 2.95633 76.9176 3.76588 77.7272C4.57544 78.5368 5.67312 78.992 6.818 78.9931H30.26C31.4049 78.992 32.5026 78.5368 33.3121 77.7272C34.1217 76.9176 34.5769 75.82 34.578 74.6751V25.3251C34.5769 24.1802 34.1217 23.0825 33.3121 22.273C32.5026 21.4634 31.4049 21.0081 30.26 21.0071ZM4.967 30.2591H32.111V68.5071H4.967V30.2591ZM6.818 23.4741H30.26C30.7508 23.4746 31.2213 23.6698 31.5683 24.0168C31.9153 24.3638 32.1105 24.8343 32.111 25.3251V27.7921H4.967V25.3251C4.96753 24.8343 5.16271 24.3638 5.50973 24.0168C5.85675 23.6698 6.32725 23.4746 6.818 23.4741ZM30.26 76.5261H6.818C6.32725 76.5256 5.85675 76.3304 5.50973 75.9833C5.16271 75.6363 4.96753 75.1658 4.967 74.6751V70.9751H32.111V74.6751C32.1105 75.1658 31.9153 75.6363 31.5683 75.9833C31.2213 76.3304 30.7508 76.5256 30.26 76.5261Z"
                fill="white"
            />
            <path
                d="M18.54 71.8999C18.1741 71.8997 17.8163 72.008 17.512 72.2111C17.2077 72.4142 16.9704 72.703 16.8302 73.041C16.69 73.379 16.6531 73.7509 16.7243 74.1098C16.7955 74.4687 16.9715 74.7985 17.2301 75.0573C17.4887 75.3162 17.8182 75.4926 18.1771 75.5642C18.5359 75.6357 18.9079 75.5993 19.246 75.4595C19.5841 75.3196 19.8732 75.0827 20.0766 74.7785C20.2801 74.4744 20.3888 74.1168 20.389 73.7509C20.3884 73.2605 20.1935 72.7903 19.8469 72.4433C19.5004 72.0964 19.0304 71.901 18.54 71.8999Z"
                fill="white"
            />
            <path
                d="M93.1819 46.916H43.8309C42.6862 46.9171 41.5887 47.3722 40.7792 48.1815C39.9697 48.9909 39.5143 50.0883 39.5129 51.233V74.675C39.514 75.8199 39.9693 76.9176 40.7788 77.7271C41.5884 78.5367 42.6861 78.992 43.8309 78.993H93.1819C94.3268 78.992 95.4245 78.5367 96.2341 77.7271C97.0436 76.9176 97.4989 75.8199 97.4999 74.675V51.233C97.4986 50.0883 97.0432 48.9909 96.2337 48.1815C95.4242 47.3722 94.3266 46.9171 93.1819 46.916ZM88.2479 49.383V76.526H49.9999V49.383H88.2479ZM41.9799 74.675V51.233C41.9805 50.7424 42.1757 50.272 42.5227 49.9251C42.8698 49.5782 43.3403 49.3833 43.8309 49.383H47.5309V76.526H43.8309C43.3402 76.5255 42.8697 76.3303 42.5227 75.9833C42.1757 75.6363 41.9805 75.1658 41.9799 74.675ZM95.0329 74.675C95.0324 75.1658 94.8372 75.6363 94.4902 75.9833C94.1432 76.3303 93.6727 76.5255 93.1819 76.526H90.7149V49.383H93.1819C93.6726 49.3833 94.1431 49.5782 94.4902 49.9251C94.8372 50.272 95.0324 50.7424 95.0329 51.233V74.675Z"
                fill="white"
            />
            <path
                d="M44.756 61.1001C44.3899 61.1001 44.032 61.2087 43.7276 61.4121C43.4231 61.6156 43.1859 61.9047 43.0458 62.243C42.9058 62.5813 42.8692 62.9535 42.9407 63.3126C43.0122 63.6717 43.1886 64.0015 43.4475 64.2603C43.7065 64.5191 44.0364 64.6953 44.3955 64.7667C44.7547 64.838 45.1269 64.8012 45.4651 64.6609C45.8033 64.5207 46.0923 64.2833 46.2956 63.9787C46.4988 63.6742 46.6072 63.3162 46.607 62.9501C46.6065 62.4594 46.4113 61.989 46.0642 61.6422C45.7172 61.2953 45.2467 61.1004 44.756 61.1001Z"
                fill="white"
            />
            <path
                d="M40.747 23.4741C43.6099 23.4762 46.3549 24.6141 48.3797 26.6379C50.4045 28.6618 51.5436 31.4063 51.547 34.2691V37.7691L50.16 36.3841C49.9288 36.1528 49.6151 36.0229 49.288 36.0229C48.961 36.0229 48.6473 36.1528 48.416 36.3841C48.1848 36.6154 48.0548 36.929 48.0548 37.2561C48.0548 37.5832 48.1848 37.8968 48.416 38.1281L51.905 41.6181C52.0192 41.7326 52.1553 41.823 52.305 41.8841C52.5308 41.9784 52.7795 42.0034 53.0195 41.9561C53.2595 41.9087 53.48 41.7911 53.653 41.6181L57.142 38.1281C57.3624 37.8949 57.4832 37.585 57.4788 37.2641C57.4743 36.9433 57.345 36.6369 57.1182 36.4099C56.8915 36.1828 56.5852 36.0532 56.2644 36.0484C55.9435 36.0435 55.6335 36.164 55.4 36.3841L54.016 37.7681V34.2681C54.0115 30.7511 52.6118 27.3795 50.1242 24.8933C47.6365 22.4071 44.2641 21.0095 40.747 21.0071C40.5821 21.0024 40.4179 21.0308 40.2641 21.0907C40.1104 21.1506 39.9702 21.2407 39.8518 21.3557C39.7335 21.4707 39.6395 21.6083 39.5752 21.7603C39.511 21.9123 39.4779 22.0756 39.4779 22.2406C39.4779 22.4056 39.511 22.5689 39.5752 22.7209C39.6395 22.8729 39.7335 23.0105 39.8518 23.1255C39.9702 23.2405 40.1104 23.3306 40.2641 23.3905C40.4179 23.4504 40.5821 23.4788 40.747 23.4741Z"
                fill="white"
            />
        </svg>
    );
};

export const RetryIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M1 4.00008V10.0001M1 10.0001H7M1 10.0001L5.64 5.64008C6.71475 4.56479 8.04437 3.77928 9.50481 3.35685C10.9652 2.93442 12.5089 2.88883 13.9917 3.22433C15.4745 3.55984 16.8482 4.26551 17.9845 5.27549C19.1209 6.28548 19.9828 7.56686 20.49 9.00008M23 20.0001V14.0001M23 14.0001H17M23 14.0001L18.36 18.3601C17.2853 19.4354 15.9556 20.2209 14.4952 20.6433C13.0348 21.0657 11.4911 21.1113 10.0083 20.7758C8.52547 20.4403 7.1518 19.7346 6.01547 18.7247C4.87913 17.7147 4.01717 16.4333 3.51 15.0001"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const CheckIcon = () => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
            <path
                d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
                fill="#FEF7FF"
            />
        </svg>
    );
};

export const FlagIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const ShareIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path
                d="M9.30583 14.6358L16.705 18.9475M16.6942 7.05246L9.30583 11.3641M22.75 5.41663C22.75 7.21155 21.2949 8.66663 19.5 8.66663C17.7051 8.66663 16.25 7.21155 16.25 5.41663C16.25 3.6217 17.7051 2.16663 19.5 2.16663C21.2949 2.16663 22.75 3.6217 22.75 5.41663ZM9.75 13C9.75 14.7949 8.29493 16.25 6.5 16.25C4.70507 16.25 3.25 14.7949 3.25 13C3.25 11.205 4.70507 9.74996 6.5 9.74996C8.29493 9.74996 9.75 11.205 9.75 13ZM22.75 20.5833C22.75 22.3782 21.2949 23.8333 19.5 23.8333C17.7051 23.8333 16.25 22.3782 16.25 20.5833C16.25 18.7884 17.7051 17.3333 19.5 17.3333C21.2949 17.3333 22.75 18.7884 22.75 20.5833Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
