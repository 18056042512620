export default [
    { code: 'sq-AL', name: 'Shqip', engName: 'Albanian' },
    { code: 'ar-AR', name: 'العربية', engName: 'Arabic' },
    { code: 'az-AZ', name: 'Azərbaycan', engName: 'Azerbaijani' },
    { code: 'bn-BD', name: 'বাংলা', engName: 'Bengali' },
    { code: 'bg-BG', name: 'Български', engName: 'Bulgarian' },
    { code: 'zh-CN', name: '中文', engName: 'Chinese' },
    { code: 'hr-HR', name: 'Hrvatski', engName: 'Croatian' },
    { code: 'cs-CZ', name: 'Čeština', engName: 'Czech' },
    { code: 'da-DK', name: 'Dansk', engName: 'Danish' },
    { code: 'nl-NL', name: 'Nederlands', engName: 'Dutch' },
    { code: 'et-EE', name: 'Eesti', engName: 'Estonian' },
    { code: 'fi-FI', name: 'Suomi', engName: 'Finnish' },
    { code: 'fr-FR', name: 'Français', engName: 'French' },
    { code: 'ka-GE', name: 'ქართული', engName: 'Georgian' },
    { code: 'de-DE', name: 'Deutsch', engName: 'German' },
    { code: 'el-GR', name: 'Ελληνικά', engName: 'Greek' },
    { code: 'hi-IN', name: 'हिन्दी', engName: 'Hindi' },
    { code: 'hu-HU', name: 'Magyar', engName: 'Hungarian' },
    { code: 'is-IS', name: 'Íslenska', engName: 'Icelandic' },
    { code: 'id-ID', name: 'Indonesia', engName: 'Indonesian' },
    { code: 'ga-IE', name: 'Gaeilge', engName: 'Irish' },
    { code: 'it-IT', name: 'Italiano', engName: 'Italian' },
    { code: 'ja-JP', name: '日本語', engName: 'Japanese' },
    { code: 'kk-KZ', name: 'Қазақ тілі', engName: 'Kazakh' },
    { code: 'ko-KR', name: '한국어', engName: 'Korean' },
    { code: 'ku-TR', name: 'Kurdî', engName: 'Kurdish' },
    { code: 'lv-LV', name: 'Latviešu', engName: 'Latvian' },
    { code: 'lt-LT', name: 'Lietuvių', engName: 'Lithuanian' },
    { code: 'mk-MK', name: 'Македонски', engName: 'Macedonian' },
    { code: 'ms-MY', name: 'Bahasa Melayu', engName: 'Malay' },
    { code: 'mt-MT', name: 'Malti', engName: 'Maltese' },
    { code: 'mn-MN', name: 'Монгол', engName: 'Mongolian' },
    { code: 'no-NO', name: 'Norsk', engName: 'Norwegian' },
    { code: 'fa-IR', name: 'فارسی', engName: 'Persian' },
    { code: 'pl-PL', name: 'Polski', engName: 'Polish' },
    { code: 'pt-PT', name: 'Português', engName: 'Portuguese' },
    { code: 'ro-RO', name: 'Română', engName: 'Romanian' },
    { code: 'ru-RU', name: 'Русский', engName: 'Russian' },
    { code: 'sr-RS', name: 'Српски', engName: 'Serbian' },
    { code: 'sk-SK', name: 'Slovenčina', engName: 'Slovak' },
    { code: 'sl-SI', name: 'Slovenščina', engName: 'Slovenian' },
    { code: 'es-ES', name: 'Español', engName: 'Spanish' },
    { code: 'sw-KE', name: 'Kiswahili', engName: 'Swahili' },
    { code: 'sv-SE', name: 'Svenska', engName: 'Swedish' },
    { code: 'th-TH', name: 'ไทย', engName: 'Thai' },
    { code: 'tr-TR', name: 'Türkçe', engName: 'Turkish' },
    { code: 'uk-UA', name: 'Українська', engName: 'Ukrainian' },
    { code: 'vi-VN', name: 'Tiếng Việt', engName: 'Vietnamese' },
];
