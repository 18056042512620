const title = `Introduction`;
const content = `
Various conditions can affect the handling of your vehicle. These can be broadly categorized into:
Weather and light conditions.
Control and speed.
Road surfaces.
`;

export default {
    title,
    content,
};
